'use client';

import { useCallback, useEffect, useRef, useState } from 'react';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { UseQueryResult } from '@tanstack/react-query';
import {
  SORT_SELECT_OPTIONS,
  mapQueryBy,
  mapSortBy,
  parseIndex,
  parseSortSelectValue,
  removeUnsupportedFilters,
  sortSelectValue,
  CONDITIONS,
  isFacetFilterSelected,
  mergeFilters,
  clearFacetValue,
  parseFilterString,
  parseAppliedFilters,
  allDefaultFacets,
  formatMoney,
} from 'sdk';

import { Book, Title, TypesenseSearchProps, DefaultFacets } from 'types';
import { ModalState } from '~/state/reducers/modal';
import GridFacets from './grid-facets';
import Button from './button';
import FilterSVG from '../assets/svg/icons/filter';
import ArrowDownBtnSVG from '../assets/svg/icons/arrow-down-btn';
import ArrowUpBtnSVG from '../assets/svg/icons/arrow-up-btn';
import { numberWithCommas } from 'sdk';
import { Links } from '../lib/helpers/link-helper';
import { useGlobalState } from '../state';
import { Action, ActionType } from '../state/types';
import styles from '../styles/components/extracted-facets.module.scss';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import useAnalytics from '../lib/hooks/use-analytics';
import { Flex } from './layout/flex';
import COLORS from '../lib/helpers/color-helper';
import DropDown from './dropdown';
import Pill from './pill';
import CloseSVG from '~/assets/svg/icons/close';
import Select from './select';

interface ExtractedFacets {
  searchResults: UseQueryResult<SearchResponse<Book | Title>, unknown>;
  searchOptions: TypesenseSearchProps;
  location?: string;
}

export default function ExtractedFacets(props: ExtractedFacets) {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { searchResults, searchOptions } = props;
  const [showAllStates, setShowAllStates] = useState(false);
  const [query, setQuery] = useState(
    props.searchOptions.params?.q && props.searchOptions.params?.q !== '*' ? props.searchOptions.params?.q : ''
  );
  const { analyticEvents } = useAnalytics();
  const { state, dispatch } = useGlobalState();
  const [searchFacetsVisible, setSearchFacetsVisible] = useState(false);
  const [extractedModalVisible, setExtractedModalVisible] = useState({ value: false, type: '' });

  const [showConditionDropdown, setShowConditionDropdown] = useState(false);
  const [showFormatDropdown, setShowFormatDropdown] = useState(false);
  const dropdownContainerRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  const IS_TITLE_QUERY = searchOptions?.index === 'titles';
  const [gridLastUpdated, setGridLastUpdated] = useState(props.searchResults.dataUpdatedAt);

  function routerProps(filter: string) {
    router.push(
      Links.searchWithQueryAppRouter({
        pathname,
        searchParams,
        options: {
          ...searchOptions,
          index: searchOptions?.index || 'books',
          params: {
            ...searchOptions.params,
            filter_by: filter,
            page: 0,
          },
        },
      }).url
    );
  }

  function handleClearAllFilters(option: string[]) {
    routerProps(clearFacetValue(searchOptions?.params?.filter_by, option));
  }

  function handleOnClick(facetName: string, value: string, action: 'AND' | 'OR' = 'AND') {
    routerProps(mergeFilters(searchOptions?.params?.filter_by, `${facetName}:=${value}`, action));
  }

  function handlePillClick(field: string, value: string) {
    const facet = field + ':' + value;
    const option = ['amount', 'hashtags', 'discounts.type'].includes(field) ? 'AND' : 'OR';
    routerProps(mergeFilters(searchOptions?.params?.filter_by, facet, option));
  }

  function formatFilterAmount(amount: string) {
    let finalAmount: string = '';
    if (amount.startsWith('>=') || amount.startsWith('<=')) {
      const direction = amount.startsWith('>=') ? ' & above' : ' & below';
      const newValue = amount.replace('>=', '').replace('<=', '');
      const dollarValueString = formatMoney(Number(newValue), true, true);
      finalAmount = dollarValueString + direction;
    } else if (amount.startsWith('[')) {
      const amountString = amount.replace(/\[|\]/g, '');
      let [min, max] = amountString.split('..').map((val) => formatMoney(Number(val), true, true));
      finalAmount = `${min} - ${max}`;
    }
    return finalAmount;
  }

  function formatDiscountType(amount: string) {
    let discountType = '';
    if (amount === 'shipping') {
      discountType = 'Free Shipping';
    } else {
      discountType = 'On Sale';
    }
    return discountType;
  }

  const handleFilterButtonPress = useCallback(() => {
    setSearchFacetsVisible(true);
    const index = searchOptions.index;
    return dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Filters',
        component: <GridFacets {...props} />,
        removeBottomPadding: true,
      },
    } as Action<ModalState>);
  }, [dispatch, props]);

  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.FLUSH_MODAL_DATA,
      });
    };
  }, [dispatch]);

  useEffect(() => {
    if (searchFacetsVisible && gridLastUpdated !== props.searchResults.dataUpdatedAt) {
      setGridLastUpdated(props.searchResults.dataUpdatedAt);
      return handleFilterButtonPress();
    }
  }, [searchFacetsVisible, gridLastUpdated, handleFilterButtonPress, props.searchResults.dataUpdatedAt]);

  useEffect(() => {
    if (extractedModalVisible.value && gridLastUpdated !== props.searchResults.dataUpdatedAt) {
      setGridLastUpdated(props.searchResults.dataUpdatedAt);
      extractedModalVisible.type === 'condition' ? handleCondtionPress() : handleFormatPress();
    }
  }, [extractedModalVisible.value, gridLastUpdated, handleOnClick, props.searchResults.dataUpdatedAt]);

  useEffect(() => {
    if (!state.modal.data.active) {
      setSearchFacetsVisible(false);
      setExtractedModalVisible({ value: false, type: '' });
    }
  }, [state.modal.data.active]);

  const getContainerWidth = () => {
    return document.getElementById('container').offsetWidth;
  };

  useEffect(() => {
    function handleResize() {
      const containerWidth = getContainerWidth();
      const IS_MOBILE = containerWidth < 768;
      setIsMobile(IS_MOBILE);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
  }, []);

  const handleCondtionPress = () => {
    setExtractedModalVisible({ value: true, type: 'condition' });
    return dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Condition',
        component: conditionDropdown(true),
      },
    });
  };

  const handleFormatPress = () => {
    setExtractedModalVisible({ value: true, type: 'format' });
    return dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Format',
        component: formatDropdown(true),
      },
    });
  };

  function conditionDropdown(isDispatchModal: boolean) {
    const conditionFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === 'condition'
    )?.counts;
    if (!conditionFacets?.length) return null;

    const conditionOptions = CONDITIONS.map((condition) => {
      const conditionCount = conditionFacets.find((c) => c.value === condition)?.count;
      return (
        <div className={styles['options']} key={condition}>
          <div className={styles['options-checkbox']}>
            <input
              onChange={() => handleOnClick('condition', condition, 'OR')}
              id={condition}
              type='checkbox'
              name={condition}
              value={condition}
              checked={isFacetFilterSelected(searchOptions?.params?.filter_by, 'condition', condition)}
            />
            <label htmlFor={condition}>
              {condition}
              {conditionCount && <span>{numberWithCommas(conditionCount)}</span>}
            </label>
          </div>
        </div>
      );
    });

    if (isMobile) {
      if (isDispatchModal) {
        return <div>{conditionOptions}</div>;
      } else {
        return null;
      }
    } else {
      if (showConditionDropdown) {
        return (
          <DropDown open={showConditionDropdown} onHide={() => setShowConditionDropdown(false)}>
            <section ref={dropdownContainerRef}>
              <div>{conditionOptions}</div>
            </section>
          </DropDown>
        );
      } else {
        return null;
      }
    }
  }

  function dropdownArrow(stateVariable: boolean) {
    return (
      <div className={styles.arrow}>
        {stateVariable ? (
          <ArrowUpBtnSVG strokeColor={COLORS.copyDark} />
        ) : isMobile ? (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        ) : (
          <ArrowDownBtnSVG strokeColor={COLORS.copyDark} />
        )}
      </div>
    );
  }

  function formatDropdown(isDispatchModal: boolean) {
    const facetKey = 'format.display';
    const formatFacets = searchResults?.data?.facet_counts?.find((c) => (c.field_name as any) === facetKey)?.counts;
    if (!formatFacets?.length) return null;

    const formatOptions = formatFacets.map((category) => {
      const { count, value } = category;
      return (
        <div className={styles['options']} key={value}>
          <div className={styles['options-checkbox']}>
            <input
              onChange={() => handleOnClick(facetKey, value)}
              id={`${value}-${count}`}
              type='checkbox'
              name={`${value}-${count}`}
              value={`${value}-${count}`}
              checked={isFacetFilterSelected(searchOptions?.params?.filter_by, facetKey, value)}
            />
            <label htmlFor={`${value}-${count}`}>
              {value} <span>{numberWithCommas(count)}</span>
            </label>
          </div>
        </div>
      );
    });

    if (isMobile) {
      if (isDispatchModal) {
        return <div>{formatOptions}</div>;
      } else {
        return null;
      }
    } else {
      if (showFormatDropdown) {
        return (
          <DropDown
            open={showFormatDropdown}
            onHide={() => {
              setShowFormatDropdown(false);
            }}
          >
            <section ref={dropdownContainerRef}>
              <div>{formatOptions}</div>
            </section>
          </DropDown>
        );
      } else {
        return null;
      }
    }
  }

  function renderSortDropdown() {
    const SHOULD_HIDE_TITLE_SORT = props.location === 'bookstore';
    let FILTERED_SORT_SELECT_OPTIONS = SHOULD_HIDE_TITLE_SORT
      ? SORT_SELECT_OPTIONS.filter((sso) => parseIndex(sso.value) !== 'titles')
      : SORT_SELECT_OPTIONS;

    let indices = [searchOptions?.index];
    if (searchOptions?.index === 'books' || searchOptions?.index === 'titles') indices = ['books', 'titles'];
    FILTERED_SORT_SELECT_OPTIONS = FILTERED_SORT_SELECT_OPTIONS.filter((sso) => indices.includes(sso.index));

    return (
      <div>
        <Select
          value={sortSelectValue(searchOptions)}
          options={FILTERED_SORT_SELECT_OPTIONS}
          className={styles['sort-container']}
          onChange={(ev) => {
            const { index, sort_by } = parseSortSelectValue(ev.target.value);
            analyticEvents?.generalTrack('Search Action', {
              type: 'sort-results',
              sortBy: sort_by,
              location: props.location || 'unknown',
            });
            return router.push(
              Links.searchWithQueryAppRouter({
                pathname,
                searchParams,
                options: {
                  ...searchOptions,
                  index,
                  params: {
                    ...searchOptions.params,
                    sort_by,
                    filter_by: removeUnsupportedFilters(
                      searchOptions.params?.filter_by || '',
                      index,
                      searchOptions?.index
                    ),
                    query_by: mapQueryBy(index),
                    page: 0,
                  },
                },
              }).url
            );
          }}
          transparent={false}
        />
      </div>
    );
  }

  const appliedFiltersArray = parseAppliedFilters(searchOptions?.params?.filter_by);

  function doesFacetHaveFilters(appliedFiltersArray, facetKey: string) {
    return appliedFiltersArray.some((filter) => filter.field === facetKey);
  }

  return (
    <>
      <Flex id='container' direction='row' justify='space-between'>
        <div style={{ width: '400px' }}>
          <div className={styles['filter-container']}>
            <div className={styles['buttons-container']}>
              <Button
                text={`Filters ${appliedFiltersArray.length > 0 ? `(${appliedFiltersArray.length})` : ''}`}
                style='outline'
                size='xtra-small'
                className={`${styles['filter-buttons']} ${appliedFiltersArray.length > 0 ? styles['filter-buttons-selected'] : ''
                  }`}
                icon={<FilterSVG fillColor={'var(--copy)'} />}
                onPress={() => {
                  analyticEvents?.generalTrack('Search Action', {
                    type: 'extracted-facets',
                    subtype: 'filter',
                    location: props.location || 'unknown',
                  });
                  handleFilterButtonPress();
                }}
              ></Button>

              <div style={{}} className={styles['mobile-sort']}>
                {renderSortDropdown()}
              </div>

              {searchOptions.index === 'titles' ? null : (
                <>
                  <div className={styles['parent-container']}>
                    <Button
                      text='Condition'
                      style='outline'
                      size='xtra-small'
                      className={`${styles['filter-buttons']} ${doesFacetHaveFilters(appliedFiltersArray, 'condition') ? styles['filter-buttons-selected'] : ''
                        }`}
                      onPress={() => {
                        analyticEvents?.generalTrack('Search Action', {
                          type: 'extracted-facets',
                          subtype: 'condition',
                          location: props.location || 'unknown',
                        });
                        if (isMobile) {
                          setShowConditionDropdown(false);
                          setExtractedModalVisible({ value: true, type: 'condition' });
                          dispatch({
                            type: ActionType.ADD_MODAL_DATA,
                            payload: {
                              title: 'Condition',
                              component: conditionDropdown(true),
                            },
                          });
                        } else {
                          setShowConditionDropdown(!showFormatDropdown);
                        }
                      }}
                      iconPosition='right'
                      icon={dropdownArrow(showConditionDropdown)}
                    ></Button>
                    {!isMobile && <div className={styles['dropdown-menu']}>{conditionDropdown(true)}</div>}
                  </div>
                  <div className={styles['parent-container']}>
                    <Button
                      text='Format'
                      style='outline'
                      size='xtra-small'
                      className={`${styles['filter-buttons']} ${doesFacetHaveFilters(appliedFiltersArray, 'format.display')
                          ? styles['filter-buttons-selected']
                          : ''
                        }`}
                      iconPosition='right'
                      icon={dropdownArrow(showFormatDropdown)}
                      onPress={() => {
                        analyticEvents?.generalTrack('Search Action', {
                          type: 'extracted-facets',
                          subtype: 'format',
                          location: props.location || 'unknown',
                        });
                        if (isMobile) {
                          setShowFormatDropdown(false);
                          setExtractedModalVisible({ value: true, type: 'format' });
                          dispatch({
                            type: ActionType.ADD_MODAL_DATA,
                            payload: {
                              title: 'Format',
                              component: formatDropdown(true),
                            },
                          });
                        } else {
                          setShowFormatDropdown(!showFormatDropdown);
                        }
                      }}
                    ></Button>

                    {!isMobile && <div className={styles['dropdown-menu-format']}>{formatDropdown(true)}</div>}
                  </div>
                </>
              )}

              <Button
                text='Titles Only'
                style={searchOptions?.index === 'titles' ? 'secondary' : 'outline'}
                className={`${styles['filter-buttons']} ${searchOptions?.index === 'titles' ? styles['filter-buttons-selected'] : ''
                  }`}
                onPress={() => {
                  analyticEvents?.generalTrack('Search Action', {
                    type: 'extracted-facets',
                    subtype: 'titles-only',
                    location: props.location || 'unknown',
                  });
                  const index = searchOptions?.index === 'titles' ? 'books' : 'titles';
                  router.push(
                    Links.searchWithQueryAppRouter({
                      pathname,
                      searchParams,
                      options: {
                        ...searchOptions,
                        index: index,
                        params: {
                          ...searchOptions.params,
                          sort_by: mapSortBy(index),
                          filter_by: removeUnsupportedFilters(
                            searchOptions.params?.filter_by || '',
                            index,
                            searchOptions?.index
                          ),
                          query_by: mapQueryBy(index),
                          page: 0,
                        },
                      },
                    }).url
                  );
                }}
              ></Button>
            </div>
          </div>
          <div className={styles['applied-filters-container']}>
            {appliedFiltersArray.length > 0 && (
              <>
                {appliedFiltersArray.map((filter, key) => {
                  return (
                    <div key={key}>
                      <Pill
                        key={key}
                        text={
                          filter.field === 'amount'
                            ? formatFilterAmount(filter.value)
                            : filter.field === 'discounts.type'
                              ? formatDiscountType(filter.value)
                              : filter.value
                        }
                        className={styles['pill']}
                        icon={
                          <div className={styles['pill-icon']}>
                            <CloseSVG strokeColor={COLORS.copy} />
                          </div>
                        }
                        iconPosition='right'
                        outline={true}
                        onClick={() => {
                          handlePillClick(filter.field, filter.value);
                        }}
                      />
                    </div>
                  );
                })}

                <Button
                  text='Clear all'
                  style='clear'
                  onPress={() => {
                    handleClearAllFilters(allDefaultFacets);
                  }}
                  className={styles['clear-all-button']}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles['desktop-sort']} style={{}}>
          {renderSortDropdown()}
        </div>
      </Flex>
    </>
  );
}
