import React, { useEffect, useState } from 'react';

import { parseTypesenseSavedSearch, serializeTypesenseOptions } from 'sdk';
import { TypesenseSearchProps } from 'types';

import { fetchCollectionDocByPath, saveSearch, updateSavedSearchResultsCount } from '~/clients/firebase/client';

import { useGlobalState } from '~/state';
import { ActionType } from '~/state/types';

import { v5 as uuid } from 'uuid';

import BookMarkSVG from '~/assets/svg/icons/book-mark';
import BookMarkSolidSVG from '~/assets/svg/icons/book-mark-solid';
import useAnalytics from '../lib/hooks/use-analytics';

export default function SaveSearchButton({ typesenseOptions, location }: { typesenseOptions?: TypesenseSearchProps; location?: string; }) {

  const { state, dispatch } = useGlobalState();

  const uid = state.user?.data?.uid;

  const [saved, setSaved] = useState<boolean>(false);

  const { analyticEvents } = useAnalytics();

  const updateResultsCount = async ({ uid, searchId }: { uid: string, searchId: string; }) => {
    await updateSavedSearchResultsCount({ uid, searchId });
    return;
  };

  useEffect(() => {
    let mounted = true;

    if (!uid) return;

    if (!typesenseOptions) {
      setSaved(false);
      return;
    }

    const { serializedOptions } = serializeTypesenseOptions({ typesenseOptions });

    const searchId = uuid(serializedOptions, uuid.URL) as string;

    fetchCollectionDocByPath(
      searchId,
      `users/${uid}/saved_searches`
    )
      .then(data => {
        if (!mounted) return;

        if (!data) {
          setSaved(false);
        } else {
          setSaved(true);
          updateResultsCount({ uid, searchId });
        }
      })
      .catch(e => {
        console.log('e', e);
      });
    return () => {
      mounted = false;
    };
  }, [typesenseOptions, uid]);

  const handleSaveSearch = async () => {
    if (!typesenseOptions) return;

    const searchDetails = parseTypesenseSavedSearch({ stringifiedSearchOptions: JSON.stringify(typesenseOptions) });

    try {
      const { defaultSearchName } = await saveSearch({ typesenseOptions, uid, type: saved ? 'remove' : 'add' });

      if (!saved) {
        analyticEvents?.generalTrack('Search Action', {
          type: 'save-search',
          location: location || 'unknown'
        });
        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'You saved this search!',
            message: `"${defaultSearchName}" ${searchDetails.stringify()} has been added to your saved searches.`,
          }
        });
      } else {
        dispatch({
          type: ActionType.ADD_SNACK_DATA,
          payload: {
            title: 'Saved search removed',
            message: `"${defaultSearchName}" ${searchDetails.stringify()} has been removed from your saved searches.`
          }
        });
      }
      setSaved(!saved);

    } catch (e: any) {
      setSaved(false);
      dispatch({
        type: ActionType.ADD_MODAL_DATA,
        payload: {
          title: 'Something went wrong',
          message: 'Please try again and contact support if the issue continues.'
        }
      });
    }
  };

  if (typesenseOptions.index !== 'books') return null;

  return (
    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer' }}
      onClick={handleSaveSearch}
    >
      {saved ? <BookMarkSolidSVG /> : <BookMarkSVG />}
      <h3 style={{ marginLeft: 5 }}>{saved ? 'Saved' : 'Save this search'}</h3>
    </div>
  );
}