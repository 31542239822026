'use client';

import { useCallback, useEffect, useState } from 'react';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { UseQueryResult } from '@tanstack/react-query';
import Image from 'next/image';
import {
  SORT_SELECT_OPTIONS,
  mapQueryBy,
  mapSortBy,
  parseIndex,
  parseSortSelectValue,
  removeUnsupportedFilters,
  sortSelectValue,
} from 'sdk';

import { Book, Title, TypesenseSearchProps } from 'types';

import BookTile from './tiles/book';
import GridPagination from './grid-pagination';
import GridFacets from './grid-facets';
import ShelvesFacets from './shelves-facets';
import Button from './button';
import Select from './select';
import FilterSVG from '../assets/svg/icons/filter';

import { numberWithCommas, pluralize } from 'sdk';
import { Links } from '../lib/helpers/link-helper';

import { useGlobalState } from '../state';
import { Action, ActionType } from '../state/types';

import styles from '../styles/components/grid.module.scss';
import { ModalState } from '~/state/reducers/modal';
import TitleTile from './tiles/title';
import AuthorTile from './tiles/author';
import SaveSearchButton from './save-search-button';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';
import ShelfTile from './tiles/shelf';
import useAnalytics from '../lib/hooks/use-analytics';
import { Box } from './layout/box';
import ExtractedFacets from './extracted-facets';

export const titleIndexes = ['titles', 'titles_popularity_desc', 'titles_copies_sold_desc'];
export const authorIndexes = ['author_content', 'author_books_sold'];

interface GridProps {
  searchResults: UseQueryResult<SearchResponse<Book | Title | any>, unknown>;
  searchOptions: TypesenseSearchProps;
  showFacets?: boolean;
  location?: string;
  extractedFacets?: boolean;
  saveSearchButton?: boolean;
  searchInput?: () => JSX.Element;
}

export default function Grid(props: GridProps) {
  const { showFacets = true } = props;
  const { searchResults, searchOptions, searchInput } = props;
  const { state, dispatch } = useGlobalState();
  const [searchFacetsVisible, setSearchFacetsVisible] = useState(false);
  const [gridLastUpdated, setGridLastUpdated] = useState(props.searchResults.dataUpdatedAt);
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { analyticEvents } = useAnalytics();

  const handleFilterButtonPress = useCallback(() => {
    setSearchFacetsVisible(true);
    const index = searchOptions.index;
    return dispatch({
      type: ActionType.ADD_MODAL_DATA,
      payload: {
        title: 'Filters',
        component: index === 'shelves' ? <ShelvesFacets {...props} /> : <GridFacets {...props} />,
        removeBottomPadding: true,
      },
    } as Action<ModalState>);
  }, [dispatch, props]);

  // If the Grid component is unmounted from the current view,
  // flush the modal data for cases where the filter modal is visible
  useEffect(() => {
    return () => {
      dispatch({
        type: ActionType.FLUSH_MODAL_DATA,
      });
    };
  }, [dispatch]);

  // Since the modal with the current grid facets gets dispatched with the current
  useEffect(() => {
    if (searchFacetsVisible && gridLastUpdated !== props.searchResults.dataUpdatedAt) {
      setGridLastUpdated(props.searchResults.dataUpdatedAt);
      return handleFilterButtonPress();
    }
  }, [searchFacetsVisible, gridLastUpdated, handleFilterButtonPress, props.searchResults.dataUpdatedAt]);

  // We set local state to know if the facets are visible so that we can re-dispatch
  // updated grid facets if new data comes in.
  useEffect(() => {
    if (!state.modal.data.active) {
      setSearchFacetsVisible(false);
    }
  }, [state.modal.data.active]);

  function renderSaveSearch() {
    return (
      <div className={styles.savedSearchContainer}>
        <SaveSearchButton typesenseOptions={searchOptions} location={props.location} />
      </div>
    );
  }

  function renderFacetHeader() {
    const SHOULD_HIDE_TITLE_SORT = props.location === 'bookstore'
    let FILTERED_SORT_SELECT_OPTIONS = SHOULD_HIDE_TITLE_SORT ? SORT_SELECT_OPTIONS.filter((sso) => parseIndex(sso.value) !== 'titles') : SORT_SELECT_OPTIONS;

    let indices = [searchOptions?.index];
    if (searchOptions?.index === 'books' || searchOptions?.index === 'titles') indices = ['books', 'titles'];
    FILTERED_SORT_SELECT_OPTIONS = FILTERED_SORT_SELECT_OPTIONS.filter((sso) => indices.includes(sso.index));

    const searchQueryString = searchResults?.data?.request_params?.q;

    const finalSearchString = searchQueryString?.split('-')[0].trim();

    return (
      <>
        <div
          className={`${styles.facets} ${props.location === 'bookstore' ? styles['bookstore-facets'] : ''}${props.extractedFacets ? styles.extractedFacets : ''
            }`}
        >
          <div className={props.searchInput ? styles.leftSideOptionsWithSearch : styles.leftSideOptions}>
            <div style={{ width: '100%', marginTop: '0.5rem' }}>
              <Box className={styles.resultsCountContainer}>
                <p className={styles.paragraph}>
                  {numberWithCommas(searchResults.data?.found) || 0} {pluralize('result', searchResults.data?.found)}
                  {searchQueryString?.startsWith('*') ? null : (
                    <span className={styles['paragraph-span']}> for "{finalSearchString}"</span>
                  )}
                </p>
              </Box>
              {props.saveSearchButton && <div> {renderSaveSearch()}</div>}
              <div style={{ paddingTop: '0.5rem' }} className={props.searchInput ? styles.searchInputDesktop : null}>
                {props.searchInput && props.searchInput()}
              </div>
            </div>
          </div>

          {!props.extractedFacets && (
            <div className={styles.searchAndButtons}>
              <div style={{ paddingTop: '0.5rem' }} className={styles.searchInputMobile}>
                {props.searchInput && props.searchInput()}
              </div>
              <div className={styles['facets-buttons']}>
                <Select
                  value={sortSelectValue(searchOptions)}
                  options={FILTERED_SORT_SELECT_OPTIONS}
                  onChange={(ev) => {
                    const { index, sort_by } = parseSortSelectValue(ev.target.value);
                    analyticEvents?.generalTrack('Search Action', {
                      type: 'sort-results',
                      sortBy: sort_by,
                      location: props.location || 'unknown',
                    });
                    return router.push(
                      Links.searchWithQueryAppRouter({
                        pathname,
                        searchParams,
                        options: {
                          ...searchOptions,
                          index,
                          params: {
                            ...searchOptions.params,
                            sort_by,
                            filter_by: removeUnsupportedFilters(
                              searchOptions.params?.filter_by || '',
                              index,
                              searchOptions?.index
                            ),
                            query_by: mapQueryBy(index),
                            page: 0,
                          },
                        },
                      }).url
                    );
                  }}
                  transparent={false}
                />

                <div>
                  <Button
                    onPress={() => {
                      analyticEvents?.generalTrack('Search Action', {
                        type: 'open-filters',
                        location: props.location || 'unknown',
                      });
                      handleFilterButtonPress();
                    }}
                    text={'Filter'}
                    icon={<FilterSVG fillColor={'var(--copy)'} />}
                    style={'outline'}
                    className={styles['filters-button-mobile']}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {props.extractedFacets && (
          <div style={{ marginBottom: '1rem' }}>
            <ExtractedFacets {...props} />
          </div>
        )}
      </>
    );
  }

  function renderGrid() {
    if (searchOptions?.index === 'titles') {
      return (
        <div className={styles.grid}>
          {searchResults.data?.hits?.map(({ document: title }) => {
            return (
              <TitleTile
                title={title as Title}
                key={title.id}
                source='search-results'
              />
            );
          })}
        </div>
      );
    }

    if (searchOptions?.index === 'authors') {
      return (
        <div className={styles.grid}>
          {searchResults.data?.hits?.map(({ document: author }) => {
            return (
              <AuthorTile key={author.id} author={author as any} />
            );
          })}
        </div>
      );
    }

    if (searchOptions?.index === 'shelves') {
      return (
        <div className={styles['grid--wide']}>
          {searchResults.data?.hits?.map(({ document: shelf }) => {

            return (
              <ShelfTile
                key={shelf.id}
                shelf={shelf as any}
                isPublicPage
              />
            );
          })}
        </div>
      );
    }

    return (
      <div className={styles.grid}>
        {searchResults.data?.hits?.map(({ document: book }) => {
          return (
            <BookTile
              book={book as Book}
              key={book.id}
              source='search-results'
            />
          );
        })}
      </div>
    );
  }

  function renderEmptyState() {
    if (searchResults.isFetched && searchResults.data.found === 0) {
      return (
        <section className={styles['no-results']}>
          <div className={styles['no-results-image-container']}>
            <Image
              src={'/images/not-found-magnify.png'}
              width={200}
              height={200}
              alt="No Results Found"
              className={styles['no-results-image-container-magnify']}
            />
            <Image
              src={'/images/not-found.png'}
              alt="No Results Found"
              className={styles['no-results-image-container-book']}
              fill
              sizes="100vw"
            />
          </div>
          <h2>Sorry, we could not find anything matching your search</h2>
          <Button text="Go Back To All Results" link={{ href: Links.search.index }} />
        </section>
      );
    }
  }

  return (
    <section>
      {showFacets && renderFacetHeader()}
      {renderGrid()}
      {renderEmptyState()}
      <GridPagination {...props} searchResults={searchResults.data} />
    </section>
  );
}


