'use client';

import { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { Book, Title, TypesenseSearchProps } from 'types';
import { Links } from '../lib/helpers/link-helper';
import styles from '../styles/components/grid-pagination.module.scss';
import Button from './button';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

interface GridPaginationProps {
  searchResults: SearchResponse<Book | Title | any>;
  searchOptions: TypesenseSearchProps;
}

export default function GridPagination(props: GridPaginationProps) {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { searchResults, searchOptions } = props;
  const CURRENT_PAGE = searchResults?.page;
  const MAX_PAGES = 999;
  const TOTAL_PAGES = Math.min(Math.ceil(searchResults?.found / searchResults?.request_params?.per_page), MAX_PAGES);

  const [showPreviousButton, setShouldShowPreviousButton] = useState(
    searchResults?.page ? searchResults.page > 1 : false
  );
  const [showNextButton, setShouldShowNextButton] = useState(
    searchResults?.page ? searchResults.page < TOTAL_PAGES : false
  );

  useEffect(() => {
    if (searchResults && searchResults?.page > 1) {
      setShouldShowPreviousButton(true);
    } else {
      setShouldShowPreviousButton(false);
    }

    if (searchResults && searchResults?.page < TOTAL_PAGES) {
      setShouldShowNextButton(true);
    } else {
      setShouldShowNextButton(false);
    }
  }, [searchResults]);

  function buttonLink(page: number) {
    const pageNumber = page > 1 ? page : 1;

    return Links.searchWithQueryAppRouter({
      pathname,
      searchParams,
      options: {
        ...searchOptions,
        params: {
          ...searchOptions.params,
          page: pageNumber,
        }
      },
    }).url;
  }

  function renderDirectionButton(direction: 'next' | 'previous') {
    const page = direction === 'next' ? searchResults.page + 1 : searchResults.page - 1;

    return (
      <Button
        className={styles['direction-button']}
        link={{
          href: buttonLink(page),
          passHref: true,
          replace: true,
        }}
        text={direction === 'next' ? 'Next' : 'Previous'}
        blurOnPress
      />
    );
  }

  function rangeOfPageNumbers() {
    const FIRST_PAGE = Math.max(1, CURRENT_PAGE - 2);
    let LAST_PAGE = Math.min(TOTAL_PAGES, Math.max(CURRENT_PAGE, CURRENT_PAGE + 3));

    function range(start, end) {
      return Array(end - (start - 1))
        .fill(null)
        .map((_, idx) => start + idx)
        .filter((i) => i >= 0 && i <= TOTAL_PAGES);
    }

    return range(FIRST_PAGE, LAST_PAGE);
  }

  function renderPageNumberButtons() {
    return rangeOfPageNumbers().map((i) => {
      return (
        <Button
          className={styles['number-button']}
          key={i}
          text={i}
          style={i === CURRENT_PAGE ? 'primary' : 'secondary'}
          link={{
            href: buttonLink(i),
            passHref: true,
            replace: true,
          }}
          blurOnPress
        />
      );
    });
  }

  function renderFirstPageButton() {
    if (CURRENT_PAGE >= 3) {
      return (
        <>
          <Button
            className={styles['number-button']}
            text={'1'}
            style="secondary"
            link={{
              href: buttonLink(1),
            }}
            blurOnPress
          />
          <Button
            className={styles['number-button']}
            disabled
            text="..."
            link={{
              href: buttonLink(TOTAL_PAGES),
            }}
            blurOnPress
          />
        </>
      );
    }

    return null;
  }

  function renderLastPageButton() {
    const RANGE = rangeOfPageNumbers();

    if (RANGE[RANGE.length - 1] < TOTAL_PAGES) {
      return (
        <>
          <Button className={styles['number-button']} disabled text="..." blurOnPress />
          <Button
            className={styles['number-button']}
            text={(TOTAL_PAGES).toString()}
            style="secondary"
            link={{
              href: buttonLink(TOTAL_PAGES),
            }}
            blurOnPress
          />
        </>
      );
    }

    return null;
  }

  function shouldShowPagination() {
    if (!searchResults) return null;

    return TOTAL_PAGES > 1;
  }

  if (!shouldShowPagination()) return null;

  return (
    <div className={styles['grid-pagination']}>
      <div className={styles['grid-pagination-buttons']}>
        {showPreviousButton && renderDirectionButton('previous')}
        {renderFirstPageButton()}
        {renderPageNumberButtons()}
        {renderLastPageButton()}
        {showNextButton && renderDirectionButton('next')}
      </div>
    </div>
  );
}