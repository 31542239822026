import React, { useState, useEffect } from 'react';

import { useRouter } from 'next/router';
import ThumbsUp from '~/assets/svg/icons/thumbs-up';
import BooksMosaic from '../books-mosaic';
import WishFilledIcon from '~/assets/svg/icons/wish-filled';
import ShelfIcon from '~/assets/svg/icons/shelf-icon';
import LockSVG from '~/assets/svg/icons/lock';
import Spacer from '../spacer';
import COLORS from '~/lib/helpers/color-helper';


import { fetchCollectionDocByPath } from '~/clients/firebase/client';

import useAuthenticatedAction from '~/lib/hooks/use-authenticated-action';
import useShelves from '~/lib/hooks/use-shelves';
import SessionForm from '../session-form';

import UserProfileImage from '../user-profile-image';

import { pluralize, truncate } from 'sdk';
import { Shelf, UserProfile, Book } from 'types';

import styles from '~/styles/components/tiles/shelf.module.scss';

function ShelfTile({
  shelf,
  isPublicPage,
}: {
  shelf: Shelf;
  user?: UserProfile;
  username?: string;
  isPublicPage?: boolean;
  liked?: boolean;
}) {

  const [locallyLiked, setLocallyLiked] = useState<boolean>(null!);
  const [localLikesCount, setLocalLikesCount] = useState<number>(
    shelf.likes || 0
  );
  const [user, setUser] = useState<UserProfile | null>(null);


  const { handleLike: handleShelfLike, isShelfLiked } = useShelves();

  const getUser = async () => {
    const user = await fetchCollectionDocByPath(shelf.uid, 'users');
    return user;
  };

  useEffect(() => {
    getUser()
      .then((user) => {
        setUser(user);
      });
    const shelfId = shelf.id.split(':')[1] ?? shelf.id;
    isShelfLiked({ shelfId, userId: shelf.uid }).then((liked: boolean) => setLocallyLiked(liked));

  }, [shelf]);


  const router = useRouter();

  function renderSellerInfo(seller: UserProfile) {
    if (!seller) return null;
    return (
      <div className={styles['seller']}>
        <div className={styles['seller-profile-image']}>
          <UserProfileImage user={seller} />
        </div>
      </div>
    );
  }

  const { handleAuthAction } = useAuthenticatedAction({
    actionToCall: async () => {
      setLocalLikesCount((prev) => locallyLiked ? prev - 1 : prev + 1);
      setLocallyLiked(!locallyLiked);
      const uid = user?.id || shelf.uid;
      const shelfId = shelf.id.split(':')[1] ?? shelf.id;
      await handleShelfLike({ shelfId: shelfId, userId: uid });
    }
  });

  const handleLike = () => {
    handleAuthAction({
      title: 'Login To Like This Shelf',
      message:
        'You will need an account to like a shelf. Please login or create an account with one of the methods below.',
      component: <SessionForm />,
    });
  };

  function renderLikes() {
    return (
      <>
        <Spacer size='small' />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }} onClick={(e) => {
          e.stopPropagation();
          handleLike();
        }}>
          <ThumbsUp fillColor={locallyLiked ? COLORS.primary : COLORS.copy} />
          {localLikesCount > 0 ? (
            <div style={{ paddingLeft: 2.5 }} >{localLikesCount}</div>
          ) : null}
        </div>
      </>
    );

  }

  return (
    <div
      className={
        isPublicPage
          ? `${styles['shelf-container']} ${styles['public-page']}`
          : styles['shelf-container']
      }
      onClick={(e) => {
        e.stopPropagation();
        if (!user) return;
        const shelfId = shelf.id.split(':')[1] ?? shelf.id;
        router.push(`/shelves/${user?.username}/${shelfId}`);
      }}
    >
      <div>
        {shelf.title === 'Wishlist' ? (
          shelf?.title_images?.length === 0 ? (
            <div className={styles['shelf-wishlist']}>
              <WishFilledIcon />
            </div>
          ) : (
            <BooksMosaic books={(shelf.selected_listings as Book[])} bookImagePaths={shelf.title_images} disableClick />
          )
        ) : shelf?.title_images?.length ? (
          <BooksMosaic books={(shelf.selected_listings as Book[])} bookImagePaths={shelf.title_images} disableClick />
        ) : (
          <div className={styles['shelf-empty']}>
            <ShelfIcon width={150} height={120} />
          </div>
        )}
      </div>
      <div className={styles['shelf-info']}>
        <div>
          <div className={styles['shelf-title']}>
            <h3> {truncate(shelf.title, 35)}</h3>
            <span>{!shelf.public && <LockSVG width={16} height={16} />}</span>
          </div>
          <p className={isPublicPage ? `${styles['public-page-count']}` : null}>
            {shelf.titlesCount || 0} {pluralize('book', shelf.titlesCount)}
          </p>
          {renderLikes()}
        </div>
        {Boolean(user) && renderSellerInfo(user)}
      </div>
    </div>
  );
}

export default ShelfTile;
