import COLORS from '~/lib/helpers/color-helper';

interface Props {
  width?: number;
  height?: number;
  strokeColor?: string;
}
export default function ChevronDownSVG({ width = 24, height = 24, strokeColor = COLORS.copyDark }: Props) {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18 9L12 15L6 9'
        stroke={strokeColor}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
