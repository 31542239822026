import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';

import { Shelf, TypesenseSearchProps } from 'types';
import {
  isFacetFilterSelected,
  mergeFilters,
  numberWithCommas,
  formatCategoriesPercentString
} from 'sdk';
import { SearchResponse } from 'typesense/lib/Typesense/Documents';

import { Links } from '../lib/helpers/link-helper';

import Button from './button';
import Input from './form/input';

import { HashtagFacets } from './grid-facets';

import styles from '../styles/components/grid-facets.module.scss';

interface ShevlesFacetsProps {
  searchResults: UseQueryResult<SearchResponse<Shelf>, unknown>;
  searchOptions: TypesenseSearchProps;
}

export default function ShelvesFacets(props: ShevlesFacetsProps) {
  const router = useRouter();

  const { searchResults, searchOptions } = props;

  const [query, setQuery] = useState(
    props.searchOptions.params?.q && props.searchOptions.params?.q !== '*'
      ? props.searchOptions.params?.q
      : ''
  );

  function handleClearQuery() {
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: 'shelves',
          params: {
            ...searchOptions.params,
            q: '',
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  useEffect(() => {
    if (query?.length === 0) {
      handleClearQuery();
    }
  }, [query]);

  function handleQueryChange() {
    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: 'shelves',
          params: {
            ...searchOptions.params,
            q: query,
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  function renderQueryInput() {
    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Search Terms</h2>
        </div>
        <div className={styles['facet-inputs']}>
          <Input
            onChange={(e: any) => setQuery(e.target.value)}
            placeholder='Search within your results'
            value={
              query ||
              (searchOptions?.params?.q === '*' ? '' : searchOptions?.params?.q)
            }
          />
          {query?.length > 0 && searchOptions?.params?.q !== query && (
            <Button onPress={handleQueryChange} text='Search' />
          )}
        </div>
      </div>
    );
  }

  function handleOnClick(
    facetName: string,
    value: string,
    action: 'AND' | 'OR' = 'AND'
  ) {

    router.push(
      Links.searchWithQuery({
        router,
        options: {
          ...searchOptions,
          index: 'shelves',
          params: {
            ...searchOptions.params,
            filter_by: mergeFilters(
              searchOptions?.params?.filter_by,
              `${facetName}:${value}`,
              action
            ),
            page: 0,
          },
        },
      }),
      null,
      { shallow: true, scroll: true }
    );
  }

  function renderTypeFacets() {

    const facetKey = 'categories_percents';
    const typeFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === facetKey
    )?.counts;
    if (!typeFacets?.length) return null;

    const fictionPercent = 'fiction:80';
    const nonFictionPercent = 'nonfiction:80';

    const fictionFacet = typeFacets.find((category: any) => category.value === fictionPercent);
    const nonFictionFacet = typeFacets.find((category: any) => category.value === nonFictionPercent);

    const typeOptions = () => {
      return (
        <>
          {fictionFacet &&
            <div className={styles['facet-options']} key={'fiction'}>
              <div className={styles['facet-checkbox']}>
                <input
                  onChange={() => handleOnClick(facetKey, fictionPercent, 'AND')}
                  id={`fiction`}
                  type='checkbox'
                  name={`${fictionFacet.value}-${fictionFacet.count}`}
                  value={`${fictionFacet.value}-${fictionFacet.count}`}
                  checked={isFacetFilterSelected(
                    searchOptions?.params?.filter_by,
                    facetKey,
                    fictionPercent
                  )}
                />
                <label htmlFor={`${fictionFacet.value}-${fictionFacet.count}`}>
                  {`Fiction`} <span>{numberWithCommas(fictionFacet.count)}</span>
                </label>
              </div>
            </div>
          }
          {nonFictionFacet &&
            <div className={styles['facet-options']} key={'nonfiction'}>
              <div className={styles['facet-checkbox']}>
                <input
                  onChange={() => handleOnClick(facetKey, nonFictionPercent, 'AND')}
                  id={`nonfiction`}
                  type='checkbox'
                  name={`${nonFictionFacet.value}-${nonFictionFacet.count}`}
                  value={`${nonFictionFacet.value}-${nonFictionFacet.count}`}
                  checked={isFacetFilterSelected(
                    searchOptions?.params?.filter_by,
                    facetKey,
                    nonFictionPercent
                  )}
                />
                <label htmlFor={`${nonFictionFacet.value}-${nonFictionFacet.count}`}>
                  {'Nonfiction'} <span>{numberWithCommas(nonFictionFacet.count)}</span>
                </label>
              </div>
            </div>
          }
        </>

      );
    };

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Types</h2>
        </div>
        <>{typeOptions()}</>
      </div>
    );
  }

  function renderCategoryFacets() {
    const facetKey = 'categories_percents';
    const typeFacets = searchResults?.data?.facet_counts?.find(
      (c) => (c.field_name as any) === facetKey
    )?.counts;

    const categoryPercent = '60';

    const categoryFacets = typeFacets?.
      filter((category: any) =>
        category.value !== `fiction:${categoryPercent}`
        && category.value !== `nonfiction:${categoryPercent}`
        && category.value.includes(categoryPercent)
      );

    if (!categoryFacets?.length) return null;

    const categoryOptions = () => {
      return (
        <>
          {categoryFacets?.map((category: any) => {
            const { value, count } = category;
            return (
              <div className={styles['facet-options']} key={category}>
                <div className={styles['facet-checkbox']}>
                  <input
                    onChange={() => handleOnClick(facetKey, value)}
                    id={`${value}-${count}`}
                    type='checkbox'
                    name={`${value}-${count}`}
                    value={`${value}-${count}`}
                    checked={isFacetFilterSelected(
                      searchOptions?.params?.filter_by,
                      facetKey,
                      value
                    )}
                  />
                  <label htmlFor={`${category}`}>
                    {`${formatCategoriesPercentString(category.value)}`} <span>{numberWithCommas(category.count)}</span>
                  </label>
                </div>
              </div>
            );
          })}
        </>
      );
    };

    return (
      <div className={styles.facet}>
        <div className={styles['facet-title-container']}>
          <h2 className={styles['facet-title']}>Categories</h2>
        </div>
        <>{categoryOptions()}</>
      </div>
    );

  };

  return (
    <section>
      {renderQueryInput()}
      {renderTypeFacets()}
      {renderCategoryFacets()}
      <HashtagFacets
        searchResults={searchResults.data}
        searchOptions={searchOptions}
        loading={searchResults.isLoading}
      />
    </section>
  );

}