import COLORS from "~/lib/helpers/color-helper";

interface Props {
  fillColor?: string;
  height?: number;
  width?: number;
}

export default function ThumbsUp({ fillColor = COLORS.primary, height = 18, width = 18 }: Props) {
  return (
    <svg width={width} height={height} viewBox="0 0 18 18" fill="none" >
      <path
        d="M15.2025 12.1883L15.7312 9.12825C15.7622 8.9489 15.7537 8.76494 15.7062 8.58923C15.6587 8.41352 15.5734 8.25031 15.4563 8.11101C15.3391 7.97171 15.1929 7.85969 15.028 7.78278C14.863 7.70587 14.6832 7.66593 14.5012 7.66575H10.6155C10.5248 7.66577 10.4352 7.64605 10.3529 7.60795C10.2706 7.56986 10.1976 7.5143 10.1389 7.44515C10.0802 7.37599 10.0373 7.2949 10.0132 7.20749C9.98901 7.12009 9.98416 7.02847 9.99897 6.939L10.4962 3.90525C10.5771 3.41244 10.5541 2.90816 10.4287 2.42475C10.3245 2.025 10.0162 1.70325 9.60972 1.57275L9.50097 1.5375C9.25497 1.45875 8.98722 1.4775 8.75547 1.5885C8.63098 1.64756 8.52087 1.73304 8.43279 1.83899C8.34471 1.94494 8.28079 2.06882 8.24547 2.202L7.88847 3.5775C7.77482 4.01522 7.60967 4.43792 7.39647 4.83675C7.08522 5.4195 6.60372 5.88675 6.10272 6.318L5.02347 7.248C4.87365 7.37731 4.75664 7.54031 4.68207 7.72363C4.60749 7.90694 4.57747 8.10533 4.59447 8.3025L5.20347 15.3473C5.23031 15.6588 5.373 15.949 5.60336 16.1605C5.83372 16.3719 6.13501 16.4893 6.44772 16.4895H9.93372C12.5452 16.4895 14.7735 14.67 15.2025 12.1883Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.22606 7.1032C2.371 7.09687 2.51279 7.14678 2.6218 7.24252C2.73082 7.33825 2.79862 7.47241 2.81106 7.61695L3.53856 16.044C3.55088 16.1694 3.53749 16.2961 3.49921 16.4163C3.46093 16.5364 3.39857 16.6475 3.31592 16.7427C3.23327 16.838 3.13207 16.9153 3.01851 16.9702C2.90495 17.025 2.78141 17.056 2.65543 17.0615C2.52946 17.067 2.40369 17.0467 2.28581 17.0019C2.16794 16.9571 2.06042 16.8888 1.96984 16.8011C1.87926 16.7134 1.80751 16.6081 1.75898 16.4917C1.71044 16.3753 1.68614 16.2503 1.68756 16.1242V7.66496C1.68743 7.51984 1.74338 7.38028 1.84374 7.27546C1.94409 7.17063 2.08107 7.1094 2.22606 7.1032Z"
        fill={fillColor}
      />
    </svg>
  );
}
