import { SelectHTMLAttributes, useState, useEffect, ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from '../styles/components/select.module.scss';
import ChevronDownSVG from '~/assets/svg/icons/chevron-down';

interface SelectProps {
  value: string;
  options: { name: string; value: string; }[];
  onChange: (ev: ChangeEvent<HTMLSelectElement>) => void;
  required?: boolean;
  disabled?: boolean;
  selectProps?: SelectHTMLAttributes<HTMLSelectElement>;
  className?: string;
  name?: string;
  transparent: boolean;
  hideArrow?: boolean;
}

export default function Select({
  transparent = false,
  ...props
}: SelectProps) {
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  function renderHtmlOptions() {
    return props.options.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.name}
        </option>
      );
    });
  }

  function handleOnChange(e) {
    setValue(e.target.value);
    return props.onChange(e);
  }

  const className = classNames(styles.select, props.className, {
    [styles['select--transparent']]: transparent,
  });

  return (
    <div className={className}>
      <select
        {...props.selectProps}
        name={props.name}
        required={props.required}
        disabled={props.disabled}
        value={value}
        onChange={handleOnChange}
        id='custom-select'
      >
        {renderHtmlOptions()}
      </select>
      {!props.hideArrow && <ChevronDownSVG />}
    </div>
  );
}
