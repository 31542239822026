import { Author } from 'types';
import styles from '~/styles/components/tiles/author.module.scss';
import Image from 'next/image';
import { images } from 'sdk';
import Links from '~/lib/helpers/link-helper';
import Link from 'next/link';

interface Props {
  author: Author;
}

// Used only for Grid View.
export default function AuthorTile({ author }: Props) {
  // bring in titles, so I can display title image instead of avatar.

  let photo_url: string = author?.photo_url;
  if (!photo_url?.length || photo_url == undefined)
    photo_url = 'author_images/blank-avatar.jpeg';

  return (
    <div className={styles['container']}>
      <Link
        href={
          Links.author.slug({ slug: author?.id || (author as any)?.objectID })
            .pathname
        }
        target='_blank'
      >
        <Image
          src={images(photo_url).user.profileImage}
          alt='Author image'
          unoptimized
          width={100}
          height={100}
          className={styles['author-img']}
        />
      </Link>

      <Link
        href={
          Links.author.slug({ slug: author?.id || (author as any)?.objectID })
            .pathname
        }
        target='_blank'
      >
        <div className={styles['container']}>{author.fullname}</div>
      </Link>
    </div>
  );
}
